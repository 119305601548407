import { useI18n } from "../../../helpers/hooks/useI18n";
import { useSelector } from "react-redux";
import { sessionSelectors } from "../../../state/session";

export const UserDetails = () => {
  const t = useI18n("PROFILE.");
  const user = useSelector(sessionSelectors.loggedInUserData);
  const orgInfo = useSelector(sessionSelectors.userOrganization);

  if (!user) return null;

  const { name: organizationName } = orgInfo ?? {};
  const { firstName, lastName, email, department } = user;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col md:flex-row justify-between">
        <div>{t("EMAIL")}</div>
        <div>{email}</div>
      </div>
      <div className="flex flex-col md:flex-row justify-between">
        <div>{t("FIRST_NAME")}</div>
        <div>{firstName}</div>
      </div>
      <div className="flex flex-col md:flex-row justify-between">
        <div>{t("LAST_NAME")}</div>
        <div>{lastName}</div>
      </div>
      <hr />
      <div className="flex flex-col md:flex-row justify-between">
        <div>{t("ORGANIZATION")}</div>
        <div>{organizationName}</div>
      </div>
      <div className="flex flex-col md:flex-row justify-between">
        <div>{t("DEPARTMENT")}</div>
        <div>{department}</div>
      </div>
    </div>
  );
};

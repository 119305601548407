export const INDUSTRIES: string[] = [
  "Aerospace and Defense",
  "Agriculture",
  "Automotive",
  "Biotechnology",
  "Construction",
  "Education",
  "Energy and Utilities",
  "Entertainment and Media",
  "Environmental Services",
  "Event Management",
  "Fashion and Apparel",
  "Finance",
  "Food and Beverage",
  "Government",
  "Healthcare",
  "Hospitality and Tourism",
  "Human Resources and Recruitment",
  "Information Technology",
  "Insurance",
  "Legal Services",
  "Manufacturing",
  "Marketing and Advertising",
  "Nonprofit and NGOs",
  "Pharmaceuticals",
  "Real Estate",
  "Research and Development",
  "Retail",
  "Sports and Recreation",
  "Telecommunications",
  "Transportation and Logistics"
];

export const ROLES: string[] = [
  "Accountant",
  "Architect",
  "Business Analyst",
  "Client (Building Owner)",
  "Compliance Officer",
  "Construction Manager",
  "Creative Director",
  "Customer Service",
  "Data Scientist",
  "Design Manager",
  "Designer",
  "Engineer",
  "Environmental Consultant",
  "Estimator",
  "Financial Analyst",
  "General Contractor",
  "Healthcare Administrator",
  "Human Resources Manager",
  "IT Support Specialist",
  "Legal Advisor",
  "Marketing Manager",
  "Operations Manager",
  "Permitting Specialist",
  "Product Manager",
  "Project Manager",
  "Quality Control",
  "Research Scientist",
  "Safety Officer",
  "Sales Executive",
  "Scheduler",
  "Site Manager",
  "Site Superintendent",
  "Software Engineer",
  "Subcontractor",
  "Supplier",
  "Supply Chain Manager",
  "Surveyor",
  "Systems Administrator"
];

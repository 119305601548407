import {
  UserSettingsActions,
  UserSettingsOptions
} from "./user.settings.actions";

export const userSettingsReducer = (
  state: UserSettingsOptions,
  action: UserSettingsActions
): UserSettingsOptions => {
  const updateState = (change: Partial<UserSettingsOptions>) => ({
    ...state,
    ...change
  });

  switch (action.type) {
    case "changeNotificationsSchedule":
      return updateState({ frequencyOption: action.option });
    case "changeLanguage":
      return updateState({ selectedLanguageOption: action.option });
    case "changeRole":
      return updateState({ selectedRoleOption: action.option });
    case "changeIndustry":
      return updateState({ selectedIndustryOption: action.option });
    case "setAllSettings":
      return action.value;
    default:
      return state;
  }
};

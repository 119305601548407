import React, { useEffect, useRef, useState } from "react";
import { NOOP } from "../../helpers/noop";
import { Icon } from "./icon";
import { PrimaryButton } from "./button";
import { Input } from "./input";
import { useI18n } from "../../helpers/hooks/useI18n";
import { encode } from "html-entities";

export type Option<T> = {
  label: string;
  value: T;
  disabled?: boolean;
};

type ItemRendererProps<T> = {
  option: Option<T>;
  classNameStyle?: string;
};

function DefaultItemRenderer<T>({
  option,
  classNameStyle
}: ItemRendererProps<T>) {
  return <span className={classNameStyle}>{option.label}</span>;
}

export function IconItemRenderer({
  option,
  classNameStyle
}: ItemRendererProps<string>) {
  return (
    <div className={`text-center ${classNameStyle ? classNameStyle : ""}`}>
      <Icon icon={option.value} />
    </div>
  );
}

export function ColorItemRenderer({
  option,
  classNameStyle
}: ItemRendererProps<string>) {
  return (
    <div className={`text-center ${classNameStyle ? classNameStyle : ""}`}>
      <Icon icon={"dice0"} color={option.value} />
    </div>
  );
}

export type DropdownProps<T> = {
  options: readonly Option<T>[];
  currentValue?: T;
  heading: string;
  handleChange?: (newValue: Option<T>) => void;
  isArrowVisible?: boolean;
  ItemRenderer?: React.FunctionComponent<ItemRendererProps<T>>;
  backgroundColor?: string;
  textColor?: string;
  icon?: string; // Hoylu font class (without hicon part)
  iconColor?: string;
  width?: number; // number from tailwind config i.e. 20 if we want 5 rem
  applyFlex?: boolean;
  disabled?: boolean;
  makeRelative?: boolean;
  allowCustomOption?: boolean;
  customOptionInputStyle?: React.CSSProperties;
};

export function Dropdown<T>({
  options,
  currentValue,
  heading,
  handleChange = NOOP,
  isArrowVisible = true,
  ItemRenderer = DefaultItemRenderer,
  backgroundColor = "colorSelectedLightTheme",
  textColor = "colorText",
  icon,
  iconColor,
  width,
  applyFlex = false,
  disabled = false,
  makeRelative = true,
  allowCustomOption = false,
  customOptionInputStyle
}: DropdownProps<T>) {
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLDivElement | null>(null);
  const t = useI18n("DROPDOWN.");

  const toggleDropdown = e => {
    // if key isn't enter do nothing
    if ((e.keyCode && e.keyCode !== 13) || disabled) {
      return null;
    }
    e.currentTarget.focus();
    setOpen(!open);
  };

  const toggleOption = (e, o) => {
    // if key isn't enter do nothing
    if ((e.keyCode && e.keyCode !== 13) || disabled) {
      return null;
    }
    handleChange(o);
    toggleDropdown(e);
    e.stopPropagation();
  };
  const containerStyle =
    `${makeRelative ? "relative " : ""}` +
    `${width ? "w-" + width : "w-fit"} ` +
    `${!disabled ? "" : "pointer-events-none"}`;
  const mainStyles = `select-none flex py-1 px-1.5 gap-2 rounded h-8 ${
    width ? "w-" + width : "w-fit"
  }`;
  const sharedStyles =
    `text-${textColor} ` +
    `${!disabled ? "bg-" + backgroundColor : "bg-colorButtonPrimaryDisabled"}`;
  const ulBasicStyles =
    "max-h-xs overflow-y-auto overflow-x-hidden w-fit min-w-full rounded shadow-md customScrollbar";
  const flex = applyFlex ? "flex flex-wrap" : "";
  const popupWidth = inputRef.current?.getBoundingClientRect().width;

  const [showCustomInput, setShowCustomInput] = useState(false);

  const isValueString = () => {
    return typeof options[0].value === "string";
  };

  const getCustomInput = () => {
    if (isValueString()) {
      return (
        <InputForCustomStringOption
          handleChange={handleChange as (val: Option<string>) => void}
          setShowCustomInput={setShowCustomInput}
          customOptionInputStyle={customOptionInputStyle}
        />
      );
    }

    return null;
  };

  return (
    <>
      {allowCustomOption && showCustomInput ? (
        getCustomInput()
      ) : (
        <div
          data-test-id={"dropdown"}
          tabIndex={0}
          onClick={e => toggleDropdown(e)}
          onKeyDown={e => toggleDropdown(e)}
          onBlur={() => setOpen(false)}
          className={containerStyle}
        >
          <div
            className={`${mainStyles} ${sharedStyles} ${
              width ? "" : "min-w-dropdown"
            }`}
            ref={inputRef}
          >
            {icon && (
              <span
                style={{ color: iconColor }}
                data-test-id={"dropdown-icon"}
                className={`hicon ${icon}`}
              />
            )}
            {options.find(option => currentValue === option.value)?.label ||
              heading}
            {isArrowVisible &&
              (!open ? (
                <span
                  data-test-id={"dropdown-arrow-down"}
                  className={"hicon down ml-auto"}
                />
              ) : (
                <span
                  data-test-id={"dropdown-arrow-up"}
                  className={"hicon up ml-auto"}
                />
              ))}
          </div>
          {open && (
            <div
              data-test-id={"dropdown-popup"}
              className={`absolute mt-1 pt-px-8 z-10`}
              style={{ width: popupWidth + "px" }}
            >
              <ul
                role="listbox"
                className={`${ulBasicStyles} ${sharedStyles} ${flex}`}
              >
                {options.map((o, i) => (
                  <li
                    className="m-0 list-none py-0.5 px-1.5 hover:bg-blue-100"
                    key={i}
                    role="option"
                    onClick={e => toggleOption(e, o)}
                    onKeyDown={e => toggleOption(e, o)}
                  >
                    <label className="box-border cursor-pointer block outline-0 p-px-5 truncate max-w-10">
                      <ItemRenderer option={o} />
                    </label>
                  </li>
                ))}
                {allowCustomOption && (
                  <li
                    className="m-0 list-none py-0.5 px-1.5 hover:bg-blue-100"
                    key={"other"}
                    role="option"
                    onClick={e => setShowCustomInput(true)}
                    onKeyDown={e => setShowCustomInput(true)}
                  >
                    <label className="box-border cursor-pointer block outline-0 p-px-5 truncate max-w-10">
                      <DefaultItemRenderer
                        option={{ label: t("OTHER"), value: undefined }}
                      />
                    </label>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export type InputForCustomStringOptionProps = {
  handleChange: (newValue: Option<string>) => void;
  setShowCustomInput: (value: boolean) => void;
  customOptionInputStyle?: React.CSSProperties;
  label?: string;
};

const InputForCustomStringOption = ({
  handleChange,
  setShowCustomInput,
  customOptionInputStyle = {
    backgroundColor: "var(--colorSelectedLightTheme)",
    height: "2rem",
    paddingLeft: "0.5rem"
  },
  label = ""
}: InputForCustomStringOptionProps) => {
  const t = useI18n();
  const [customValue, setCustomValue] = useState("");
  const selfRef = useRef<HTMLInputElement | null>(null);

  const saveCustomRole = () => {
    const trimmedValue = customValue.trim();

    if (trimmedValue) {
      handleChange({ label: customValue, value: encode(customValue) });
    }

    setShowCustomInput(false);
  };

  useEffect(() => {
    selfRef.current?.focus();
  }, []);

  return (
    <div className="flex items-center gap-1">
      <Input
        placeholder={t("DROPDOWN.CUSTOM_OPTION_PLACEHOLDER")}
        label={label}
        value={customValue}
        onChange={val => setCustomValue(val)}
        inputStyle={customOptionInputStyle}
        parentRef={selfRef}
      ></Input>
      <PrimaryButton onClick={saveCustomRole}>
        {customValue ? t("SAVE") : t("CLOSE")}
      </PrimaryButton>
    </div>
  );
};

import { Tile } from "../../tile";
import { ReactNode } from "react";
import { Icon } from "../../shared/icon";

type UserProfileTileProps = {
  title: string;
  children: ReactNode;
  onClick?: () => void;
  icon?: string;
};

export const UserProfileTile = ({
  title,
  children,
  icon,
  onClick
}: UserProfileTileProps) => {
  return (
    <Tile className="flex-grow py-6 max-w-3xl">
      <div className={"flex justify-between"}>
        <span className="text-lg font-bold">{title}</span>
        {icon && <Icon icon={icon} onClick={onClick} />}
      </div>
      <div className="m-4">{children}</div>
    </Tile>
  );
};

import { Option } from "../../../shared/dropdown";
import { SupportedLanguages } from "@hoylu/client-common";

export type UserSettingsOptions = {
  frequencyOption?: Option<string>;
  selectedLanguageOption?: Option<SupportedLanguages>;
  selectedIndustryOption?: Option<string>;
  selectedRoleOption?: Option<string>;
};

type ChangeNotificationsScheduleAction = {
  type: "changeNotificationsSchedule";
  option: Option<string>;
};

type ChangeLanguageAction = {
  type: "changeLanguage";
  option: Option<SupportedLanguages>;
};

type ChangeRoleAction = {
  type: "changeRole";
  option: Option<string>;
};

type ChangeIndustryAction = {
  type: "changeIndustry";
  option: Option<string>;
};

type SetAllSettings = {
  type: "setAllSettings";
  value: UserSettingsOptions;
};

export type UserSettingsActions =
  | ChangeNotificationsScheduleAction
  | ChangeLanguageAction
  | ChangeRoleAction
  | ChangeIndustryAction
  | SetAllSettings;

export const changeNotificationsSchedule = (
  option: Option<string>
): ChangeNotificationsScheduleAction => ({
  type: "changeNotificationsSchedule",
  option
});

export const updateLanguage = (
  option: Option<SupportedLanguages>
): ChangeLanguageAction => ({
  type: "changeLanguage",
  option
});

export const updateRole = (option: Option<string>): ChangeRoleAction => ({
  type: "changeRole",
  option
});

export const updateIndustry = (
  option: Option<string>
): ChangeIndustryAction => ({
  type: "changeIndustry",
  option
});

export const setUserSettings = (
  value: UserSettingsOptions
): SetAllSettings => ({
  type: "setAllSettings",
  value
});

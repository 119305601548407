import { useI18n } from "../../../../helpers/hooks/useI18n";
import { Dropdown, Option } from "../../../shared/dropdown";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { getUserSettings, saveUserSetting } from "../../../../api/users";
import { useSelector } from "react-redux";
import { sessionSelectors } from "../../../../state/session";
import { INDUSTRIES, ROLES } from "../roles.and.industries";
import { showErrorToast } from "../../../../helpers/show.toast.helpers";
import {
  extractUserSettingsFromResponse,
  IUserSettings,
  NOTIFICATION_SETTINGS_NAMESPACE,
  PERSONAL_SETTINGS_NAMESPACE,
  SupportedLanguages,
  UserSettingRequest,
  UserSettingResponse
} from "@hoylu/client-common";
import {
  setUserSettings,
  updateIndustry,
  updateLanguage,
  changeNotificationsSchedule,
  updateRole
} from "./user.settings.actions";
import { userSettingsReducer } from "./user.settings.reducer";
import { decode } from "html-entities";

export const EMAIL_NOTIFICATIONS_KEY: keyof IUserSettings =
  "emailNotificationsCronSchedule";
export const LANGUAGE_KEY: keyof IUserSettings = "currentLanguage";
export const ROLE_KEY: keyof IUserSettings = "role";
export const INDUSTRY_KEY: keyof IUserSettings = "industry";

type CronSchedule = string;
const emailNotificationOptions: Option<CronSchedule>[] = [
  {
    value: "*/15 * * * *",
    label: "15 min summary"
  },
  {
    value: "0 * * * *",
    label: "1 hour summary"
  },
  {
    value: "0 */3 * * *",
    label: "3 hours summary"
  },
  {
    value: "0 0 * * *",
    label: "1 day summary"
  },
  {
    value: "0 0 * * 1",
    label: "1 week summary"
  }
];

const languageOptions: Option<SupportedLanguages>[] = [
  {
    value: "en",
    label: "English"
  },
  {
    value: "de",
    label: "German (Deutsch)"
  }
];

export const UserSettings = () => {
  const t = useI18n("PROFILE.");
  const te = useI18n("ERRORS.");
  const user = useSelector(sessionSelectors.loggedInUserData);
  const [disableSettings, setDisableSettings] = useState(false);

  const [currentUserSettings, dispatch] = useReducer(userSettingsReducer, {});

  const generateOptions = useCallback((values: string[]) => {
    return values.map(value => ({
      value,
      label: value
    }));
  }, []);

  const industryOptions: Option<string>[] = useMemo(
    () => generateOptions(INDUSTRIES),
    [generateOptions]
  );
  const roleOptions: Option<string>[] = useMemo(
    () => generateOptions(ROLES),
    [generateOptions]
  );

  const handleSettingFetchingError = useCallback(
    (error: any) => {
      showErrorToast(te("LOAD_USER_SETTINGS"), error);
      setDisableSettings(true);
      dispatch(setUserSettings({}));
    },
    [te]
  );

  const addRoleOption = useCallback(
    (name: string) => {
      const newOption = {
        label: decode(name),
        value: name
      };

      roleOptions.push(newOption);
      return newOption;
    },
    [roleOptions]
  );

  const addIndustryOption = useCallback(
    (industry: string) => {
      const newOption = {
        label: decode(industry),
        value: industry
      };

      industryOptions.push(newOption);
      return newOption;
    },
    [industryOptions]
  );

  const findRoleOption = useCallback(
    (role: string | undefined) => {
      return roleOptions.find(option => option.value === role);
    },
    [roleOptions]
  );

  const findIndustryOption = useCallback(
    (industry: string | undefined) => {
      return industryOptions.find(option => option.value === industry);
    },
    [industryOptions]
  );

  const updateStates = useCallback(
    (responseData: UserSettingResponse[]) => {
      const extractedUserSettings =
        extractUserSettingsFromResponse(responseData);

      if (!extractedUserSettings) return;

      setDisableSettings(false);

      let frequencyOption = emailNotificationOptions.find(
        option =>
          option.value === extractedUserSettings.emailNotificationsCronSchedule
      );

      const selectedLanguageOption = languageOptions.find(
        option => option.value === extractedUserSettings.currentLanguage
      );

      let selectedRole = findRoleOption(extractedUserSettings.role);
      let selectedIndustry = findIndustryOption(extractedUserSettings.industry);

      if (extractedUserSettings.role && !selectedRole) {
        selectedRole = addRoleOption(extractedUserSettings.role);
      }

      if (extractedUserSettings.industry && !selectedIndustry) {
        selectedIndustry = addIndustryOption(extractedUserSettings.industry);
      }

      dispatch(
        setUserSettings({
          frequencyOption,
          selectedLanguageOption,
          selectedRoleOption: selectedRole,
          selectedIndustryOption: selectedIndustry
        })
      );
    },
    [addRoleOption, addIndustryOption, findIndustryOption, findRoleOption]
  );

  const generateSettingRequests = useCallback((): UserSettingRequest[] => {
    const settingsToGet = [
      EMAIL_NOTIFICATIONS_KEY,
      LANGUAGE_KEY,
      ROLE_KEY,
      INDUSTRY_KEY
    ];
    return settingsToGet.map(settingName => ({
      namespace:
        settingName === EMAIL_NOTIFICATIONS_KEY
          ? NOTIFICATION_SETTINGS_NAMESPACE
          : PERSONAL_SETTINGS_NAMESPACE,
      name: settingName
    }));
  }, []);

  useEffect(() => {
    const fetchSettings = async (userId: string) => {
      try {
        const response = await getUserSettings(
          userId,
          generateSettingRequests()
        );

        if (response.data && response.data.length > 0) {
          updateStates(response.data);
        }

        if (response.error) {
          handleSettingFetchingError(response.error);
        }
      } catch (e) {
        handleSettingFetchingError(e);
      }
    };

    if (user?.uid) {
      fetchSettings(user.uid);
    }
  }, [
    user?.uid,
    updateStates,
    generateSettingRequests,
    handleSettingFetchingError
  ]);

  const saveSetting = async <T,>(
    settingName: keyof IUserSettings,
    value: T
  ) => {
    if (user?.uid) {
      const response = await saveUserSetting<T>(user?.uid, settingName, value);

      if (response.error) {
        showErrorToast(te("CHANGE_USER_SETTINGS"), response.error);
      }

      return response.status === 200;
    }
  };

  const setLanguage = async (v: Option<SupportedLanguages>) => {
    const saved = await saveSetting<SupportedLanguages>(
      "currentLanguage",
      v.value
    );
    if (saved) dispatch(updateLanguage(v));
  };

  const setNotificationsSchedule = async (v: Option<string>) => {
    const saved = await saveSetting<string>(
      "emailNotificationsCronSchedule",
      v.value
    );
    if (saved) dispatch(changeNotificationsSchedule(v));
  };

  const setIndustry = async (v: Option<string>) => {
    const saved = await saveSetting<string>("industry", v.value);

    if (saved) {
      !findIndustryOption(v.value) && addIndustryOption(v.value);
      dispatch(updateIndustry(v));
    }
  };

  const setRole = async (v: Option<string>) => {
    const saved = await saveSetting<string>("role", v.value);

    if (saved) {
      !findRoleOption(v.value) && addRoleOption(v.value);
      dispatch(updateRole(v));
    }
  };

  const hasCurrentSettings =
    currentUserSettings && Object.keys(currentUserSettings).length > 0;

  return (
    <>
      {hasCurrentSettings ? (
        <>
          <SettingsSectionTitle title={t("SETTINGS_SECTION_1")} />
          <div className={"pl-2"}>
            <SettingsDropdown
              value={currentUserSettings.selectedLanguageOption}
              label={t("LANGUAGE")}
              options={languageOptions}
              changeHandler={setLanguage}
              disabled={disableSettings}
            />
            <SettingsDropdown
              value={currentUserSettings.frequencyOption}
              label={t("EMAIL_NOTIFICATION")}
              options={emailNotificationOptions}
              changeHandler={setNotificationsSchedule}
              disabled={disableSettings}
            />
          </div>
          <SettingsSectionTitle title={t("SETTINGS_SECTION_2")} />
          <div className={"pl-2"}>
            <SettingsDropdown
              value={currentUserSettings.selectedIndustryOption}
              label={t("INDUSTRY")}
              options={industryOptions}
              changeHandler={setIndustry}
              disabled={disableSettings}
              allowCustomOption={true}
            />
            <SettingsDropdown
              value={currentUserSettings.selectedRoleOption}
              label={t("ROLE")}
              options={roleOptions}
              changeHandler={setRole}
              disabled={disableSettings}
              allowCustomOption={true}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

type SettingsDropdownProps<T> = {
  options: Option<T>[];
  value: Option<T> | undefined;
  changeHandler: (val: Option<T>) => void;
  label: string;
  disabled: boolean;
  allowCustomOption?: boolean;
};

export const SettingsDropdown = <T,>({
  options,
  value,
  changeHandler,
  label,
  disabled,
  allowCustomOption
}: SettingsDropdownProps<T>) => {
  const t = useI18n("PROFILE.");

  return (
    <div className="flex justify-between mt-4" data-test-id={label}>
      <div>{label}</div>
      <Dropdown
        options={options}
        heading={disabled ? "" : t("DROPDOWN_PLACEHOLDER")}
        handleChange={val => changeHandler(val)}
        currentValue={value?.value}
        disabled={disabled}
        allowCustomOption={allowCustomOption}
      />
    </div>
  );
};

const SettingsSectionTitle = ({ title }: { title: string }) => {
  return <div className="font-bold mt-4">{title}</div>;
};
